// 44590
// 58875
$(document).ready(function () {
    $("#pdpEmbeddingVideosModal").on('show.bs.modal', function (event) {
        event.stopImmediatePropagation();
        var button = $(event.relatedTarget);
        var recipient = button.data('whatever');
        var iframeVideoUrl = recipient + "?autoplay=1";
        var modal = $(this);
        modal.find("#vidoePdpIframe").append('<iframe id="pdpvideo" class="pdp-video embed-responsive-item" src=' + iframeVideoUrl + ' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
    });

    $("#pdpEmbeddingVideosModal").on('hide.bs.modal', function () {
        $("#pdpvideo").remove();
    });
});
